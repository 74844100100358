<template>
  <div class='py-2 lg:py-8'>
    <content-background-cover
      background-color='#001020'
      class='z-0'
      :top-offset='0' />
    <div v-if='hasLiveSessions' 
      class='py-4 flex flex-row justify-between flex-wrap'>
      <div v-for='(room, index) in liveSessions' 
        :key='`live-session-room-${index}`'
        class='live-preveiw-box-size z-10 p-0.5 flex-shrink-0 flex flex-col justify-center items-center'>
        <div v-if='hasLiveSession(room)'
             class='w-full h-full opacity-75 hover:opacity-100 cursor-pointer relative'
             @click='openSession(room.currentSession.id)'>
          <div class='z-10 bottom-0 absolute opacity-90 uppercase text-white bg-gray-800 w-full text-center text-sm font-semibold py-1'>
            <h3 class='text-white text-2xl uppercase font-semibold'>{{ room.roomName }}</h3>
            <h5 class='hover:underline text-gray-400'>Join Session</h5>
          </div>
          <component :is='selectedLiveVideoPreviewPlayerComponent(room.currentSession.listOfContents[0])'
            :showing-content='room.currentSession.listOfContents[0]'
            class='z-10'>
          </component>
        </div>
        <div v-else class='w-full h-full opacity-50 relative bg-gray-800'>
          <div class='absolute z-20 w-full h-full flex flex-col justify-center items-center'>
            <h3 class='text-white text-2xl uppercase font-semibold'>{{ room.roomName }}</h3>
            <h5 class='text-gray-400'>No Live Session</h5>
          </div>         
        </div>
      </div>
    </div>
    <div v-else
      class='layout-container py-24 text-gray-400 text-xl text-center'>
      There are no sessions currently in progress
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions}  from 'vuex'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'

const LiveVideoPreviewContentVimeo = function() {
  return import('@/components/contents/LiveVideoPreviewContentVimeo')
}
const LiveVideoPreviewContentWecandeo = function() {
  return import('@/components/contents/LiveVideoPreviewContentWecandeo')
}


export default {
  name: 'Live',
  components: {
    ContentBackgroundCover,
    LiveVideoPreviewContentVimeo,
    LiveVideoPreviewContentWecandeo
  },
  computed: {
    ...mapState('sessions', [
      'liveSessions'
    ]),
    ...mapGetters('sessions', [
      'hasLiveSessions',
    ]),
  },
  methods: {
    ...mapActions('sessions', [
      'getLiveSessions',
      'getCurrentSessionId',
    ]),
    hasLiveSession (room) {
      return room.currentSession
    },
    openSession (sessionId) {
      this.getCurrentSessionId(sessionId).then((currentSessionId) => {
        if (currentSessionId) {
          // 다음 세션 있을 때
          this.$router.push({name: 'Session', query: {session_id: currentSessionId}})
        } else {
          // 다음 세션 없을 때
          this.$alert('There are no sessions in progress. Navigating to the programs page.', {
            type: 'info',
            confirmButtonText: 'Go to Programs'
          }).then(() => {
            this.$router.replace({name: 'Programs'})
          })
          setTimeout(() => {
            this.$router.replace({name: 'Programs'}).then(() => {
              location.reload(true)
            })
          }, 5000)
        }
      })
    },
    selectedLiveVideoPreviewPlayerComponent (showingContent) {
      if (showingContent.data.sourceUrl.includes('vimeo')) {
        return 'live-video-preview-content-vimeo'
      } else if (showingContent.data.sourceUrl.includes('wecandeo')) {
        return 'live-video-preview-content-wecandeo'
      } else {
        return ''
      }
    },
  },
  mounted () {
    this.getLiveSessions()
  }
}
</script>

<style type='scss'>
.live-preveiw-box-size {
  @apply mb-1;
  min-height: 254px;
  height: auto;
  width: 100%;
}

@media (min-width: 1024px) { 
  .live-preveiw-box-size {
    width: 33%;
  }
}
</style>

